<template>
  <div data-v-19da6b64="" class="login-count">
    <div data-v-19da6b64="" class="wrapper-count">
      <span data-v-19da6b64="" class="closeBtn iconfont icon-guanbi2" @click="$store.state.registeredDialog = false"></span>
     
      <div data-v-19da6b64="" class="wrapper" style="">
        <div data-v-19da6b64="" class="title" style="margin-bottom: 10px">
          {{ $t("register.register") }}
        </div>
        <div
          data-v-19da6b64=""
          style="
            display: flex;
            align-items: center;
            margin-left: 40px;
            font-size: 16px;
            color: rgb(153, 153, 153);
          "
        >
          <div
            data-v-19da6b64=""
            class="typeIndex active"
            style="margin-left: 30px"
          >
            <p data-v-19da6b64="">{{ $t("text21") }}</p>
            <div data-v-19da6b64="" class="i active"></div>
          </div>
        </div>
        <div data-v-19da6b64="" class="item phone acea-row row-middle">
          
          
          
          <input v-model="email" data-v-19da6b64="" :placeholder="$t('login.account')" />
        </div>
        
        <div data-v-19da6b64="" class="item pwd">
          <input
            data-v-19da6b64=""
            type="password"
           :placeholder="$t('login.password')"
            v-model="password"
          />
        </div>
        <div data-v-19da6b64="" class="item pwd" style="position: relative">
          <input
            data-v-19da6b64=""
            type="password"
            :placeholder="$t('register.captcha')"
            v-model="captcha"
          />
          <div
            @click="sendCode()"
            style="
              position: absolute;
              height: 48px;
              line-height: 48px;
              color: red;
              top: 0;
              right: 0;
              margin-right: 20px;
              cursor: pointer;
            "
          >
            {{ msg }}
          </div>
        </div>
        
        <div data-v-19da6b64="" class="checkbox-wrapper item_protocol">
          <label data-v-19da6b64="" class="well-check"
            >
            <el-checkbox v-model="checked" style="margin-left: -20px;margin-right: 10px"></el-checkbox>
            <span data-v-19da6b64="">{{ $t("register.tip") }}</span></label
          >
          <a
            data-v-19da6b64=""
            @click="$store.commit('clearDiloag');$router.push({
              path:'/privacy_agreement',
              query:{
                type:1
              }
            })"
            class="show_protocol"
            target="_blank"
            >{{ $t("register.yfxy") }}
          </a>
          {{ $t("register.and") }}
          <a
            data-v-19da6b64=""
            @click="$store.commit('clearDiloag');$router.push({
              path:'/privacy_agreement',
              query:{
                type:2
              }
            })"
            class="show_protocol"
            target="_blank"
            > {{ $t("register.yszc") }}
          </a>


        </div>
        <div data-v-19da6b64="" class="signIn bg-color" @click="submit()">{{ $t("register.register") }}</div>
        <div data-v-19da6b64="" class="fastLogin">
          {{ $t("register.had") }} <span data-v-19da6b64="" class="font-color"  @click="$store.state.registeredDialog = false;$store.state.loginDilaog = true">{{ $t("register.login") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      captcha: "",
      timer: null,
      msg: this.$t("getCode"),
      count: 60,
      checked:true,
    };
  },
  methods: {
    submit() {

      if(!this.checked){
          return this.$message.error(this.$t("register.sTip"))
        }

      if(!this.email){
        return this.$message.error(this.$t("login.email"))
        
      }
      if(!this.password){
        return this.$message.error(this.$t("login.password"))
        
      }
      if(!this.captcha){

        return this.$message.error(this.$t("login.code"))
      }
      this.$axios({
        method: "POST",
        url: "/api/wanlshop/user/register",
        data: {
          email:this.email,
          captcha: this.captcha,
          password: this.password,
        },
      }).then((res) => {
        if(res.data.code==1){
          this.$message.success(this.$t("text24"));
          this.$store.state.registeredDialog = false;
          this.$store.state.loginDilaog = true;

        }
       
      });
    },
    sendCode() {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (emailRegex.test(this.email)) {
        if (this.msg == this.$t("getCode")) {
          this.$axios({
            url: "/api/ems/send",
            method: "POST",
            data: {
              email: this.email,
            },
          }).then((res) => {
            if (res.data.code == 1) {
              this.count = 60;
              this.msg = `${this.count}${this.$t('uCodeChangeText')}`;
              this.timer = window.setInterval(() => {
                this.count--;
                this.msg = `${this.count}${this.$t('uCodeChangeText')}`;
                if (this.count == 0) {
                  window.clearInterval(this.timer);
                }
              }, 1000);
            }
          });
        } 
      } else {
        this.$message.error(this.$t("login.email"));
      }
    },
  },
};
</script>